import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DigitalService } from '../digital.service';
import { SessionCreateData } from '../SessionCreateData';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SessionResponse } from '../SessionResponse';
import { ConfigJson } from '../utils/configJson';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {
  form: FormGroup;

  constructor(public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private digitalService :DigitalService,private formBuilder: FormBuilder,) { }

  public server="eu.cc";
  public port="443";
  public protocol="https";
  public sessionData:SessionCreateData;
  public accountId="";
  public customerIdentifier="sample";
  public displayName;
  public firstName; 
  public lastName;
  public email;
  public providerId;
  public contact;
  public proxy="no";
  public extraparamKey;
  public extraparamVal;
  public messageurl="media flowing inside";
  public availableBalance;
  public customerType="";
  public sourcePage="";
  public loadEnvLanguage;
  public loadEnvConfig;



  lang :string = "English";
 
  ngOnInit() {
    // console.log("initializing dialog components");
    var localServerSettingsJSONString = localStorage.getItem('serverSettings');
    var localSessionSettingsJSONString = localStorage.getItem('sessionData')
    var localServerSettings = JSON.parse(localServerSettingsJSONString);
    var localSessionSettings = JSON.parse(localSessionSettingsJSONString);
    var urlReg = /(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/gi
    this.loadEnvConfig = ConfigJson.env;
    this.loadEnvLanguage = ConfigJson.lang;
    console.log(this.loadEnvConfig);
    if(!localStorage.getItem('savedLanguage'))
        localStorage.setItem('savedLanguage',"English");


    this.form = this.formBuilder.group({
      customerIdentifier: [null],
      displayName: [null],
      firstName: [null],
      lastName: [null],
      email: [null,Validators.required],
      contact: [null],
      availableBalance: [null,Validators.maxLength(250)],
      customerType: [null,Validators.maxLength(250)],
      sourcePage: [null],
      // accountId: [null, Validators.required],
      // providerId: [null, Validators.required],
    });

    //populate server settings
    // console.log("local server settings: " + localServerSettingsJSONString);
    if(null !== localServerSettings) {
      //this.server = localServerSettings.server;
      this.port = localServerSettings.port;
      this.protocol = localServerSettings.protocol;
      this.proxy = localServerSettings.proxy;
      this.messageurl = localServerSettings.messageurl;
      this.providerId = localServerSettings.providerId;
      this.accountId = localServerSettings.accountId;
      this.lang = localStorage.getItem("savedLanguage");
    }

    //populate session settings
    // console.log("local session settings: " + localSessionSettingsJSONString);
    if(null !== localSessionSettings) {
      // this.accountId = localSessionSettings.accountId;
      this.contact = localSessionSettings.contact;
      this.email = localSessionSettings.email;
      this.customerIdentifier = localSessionSettings.customerIdentifier
      this.firstName = localSessionSettings.firstName;
      this.lastName = localSessionSettings.lastName;
      this.displayName = localSessionSettings.displayName;

      if(null !== localSessionSettings.sessionParameters) {
        var extraparamKeys = Object.keys(localSessionSettings.sessionParameters);
        if(null !== extraparamKeys) {
          this.extraparamKey = extraparamKeys[0];
          this.extraparamVal = localSessionSettings.sessionParameters[this.extraparamKey];
        }
      }
    }   
  }

  dismiss():void{
    this.dialogRef.close();
  }

  allowControl() {
    var ctrlData = {
      isAllow: true
    }
    this.dialogRef.close(ctrlData);
  }
  denyControl() {
    var ctrlData = {
      isDeny: true
    }
    this.dialogRef.close(ctrlData);
  }
  createSession(){
    this.sessionData = new SessionCreateData(this.accountId, 
     // this.form.controls["customerIdentifier"].value,
     // this.form.controls["displayName"].value, 
     this.form.controls["email"].value,
     this.form.controls["firstName"].value + this.form.controls["lastName"].value,
      this.form.controls["firstName"].value, 
      this.form.controls["lastName"].value, 
      this.form.controls["email"].value, 
      this.form.controls["contact"].value, 
      this.providerId,
      this.form.controls["availableBalance"].value,
      this.form.controls["customerType"].value,
      this.form.controls["sourcePage"].value
    );
    // debugger;
    this.sessionData.setSessionParameters(this.extraparamKey, this.extraparamVal);
    this.sessionData.setSessionParameters('availableBalance', this.form.controls["availableBalance"].value)
    this.sessionData.setSessionParameters('customerType', this.form.controls["customerType"].value)
    this.sessionData.setSessionParameters('sourcePage', this.form.controls["sourcePage"].value)
    
    localStorage.setItem('sessionData', JSON.stringify(this.sessionData));
    this.dialogRef.close(this.sessionData);
  }
  saveSettings() {
    console.log(this.form)
    let url;
    if(this.accountId === 'WANAYR' || this.accountId === 'HHDDZV' || this.accountId === 'VMLJHT' || this.accountId === 'PAYELJ' || this.accountId === 'QNMKVM' || this.accountId === 'CUIRJC'){
      url  = 'na.cc.avayacloud.com';
    }else if(this.accountId === 'VAWTYV'){
       url = "uat.ixcc-sandbox.avayacloud.com";
    }else if(this.accountId === 'NGAWYW' || this.accountId === 'BBICTF'){
      url = 'eu.cc.avayacloud.com';
    }else if(this.accountId === 'NLYYBX' || this.accountId === 'XQMQEZ'){
      url = 'uk.cc.avayacloud.com';
    }else if(this.accountId === 'ENVJOT' || this.accountId === 'OUDRAM'){
      url = 'sa.cc.avayacloud.com';
    }else{
      url = "experience.cc.avayacloud.com";
    }
    var ctrlData = {
      isSaved: true,      
      server: url,
      port: this.port,
      protocol: this.protocol,
      proxy: this.proxy,
      messageurl: this.messageurl,
      providerId : this.providerId,
      accountId : this.accountId
    }
    
    // debugger;
    console.log('closing the log')
    
    localStorage.setItem('serverSettings', JSON.stringify(ctrlData));
    if(this.accountId === ""){
      console.log('not selected any environment');
    }else{
      this.dialogRef.close(ctrlData);
    }
  }
  captureLanguage(language:string){
    this.lang = language;
    localStorage.setItem('savedLanguage', this.lang);
    
    if(this.digitalService.getSessionId()){
      // console.log('Session exists, proceeding with update');
      this.digitalService.updateSession("language", language)
      .subscribe((result: SessionResponse) => {
        // console.log(result);
        this.digitalService.setSessionResponse(result);
      });
    }
    // console.log(language);
  }
  captureEnvironment(env:string){
    console.log('selected env'+env);
    if(env === 'WANAYR' || env === 'HHDDZV' || env === 'VMLJHT' || env === 'PAYELJ' || env === 'QNMKVM' || env === 'CUIRJC'){
      this.server = 'na.cc';
    }else if(env === 'NGAWYW' || env === 'BBICTF'){
      this.server = 'eu.cc';
    }else if(env === 'NLYYBX' || env === 'XQMQEZ'){
      this.server = 'uk.cc';
    }else if(env === 'ENVJOT' || env === 'OUDRAM'){
      this.server = 'sa.cc';
    }else{
      this.server = 'experience.cc';
    }
    
  }
}
