export var ConfigJson={

    "env":[
        {
            "displayName":"CCaaS Guided Demo 1",
            "accountId":"PAYELJ"
        },
        {
            "displayName":"CCaaS Guided Demo 2 ",
            "accountId":"BBICTF"
        },
        {
            "displayName":"CCaaS Guided Demo SFDC Scheduler",
             "accountId":"XQMQEZ"
        },

        {
            "displayName":"CCaaS Administration Demo",
            "accountId":"CUIRJC"
        },

        //{
        //    "displayName":"CCaaS Administration Demo",
        //    "accountId":"HHDDZV"
        //},
        // {
        //     "displayName":"CCaaS Alpha Pre-Production",
        //     "accountId":"KSUOMH",
        // },
        //{
        //    "displayName":"Experience01.avaya.com",
        //    "accountId":"WANAYR",
        //},
        // {
        //     "displayName":"Experience03.avaya.com",
        //     "accountId":"CXGKPA"
        // },
        // {
        //     "displayName":"Experience05.avaya.com",
        //     "accountId":"WIIMLU"
        // },
        // {
        //     "displayName":"Experience06.avaya.com",
        //     "accountId":"HJGBAT"
        // },
        {
            "displayName":"CCaaS SA",
            "accountId":"ENVJOT"
        },
        // {
        //     "displayName":"ExperienceAvaya NA-LAB",
        //     "accountId":"VMLJHT"
        // },
        {
            "displayName":"ExperienceAvaya NA 01-9000000005",
            "accountId":"QNMKVM"
        },
        //{
        //    "displayName":"ExperienceAvaya NA 02-9000000006",
        //    "accountId":"CUIRJC"
        //},
        
        // {
        //     "displayName":"ExperienceAvaya EU-LAB",
        //     "accountId":"NGAWYW"
        // },
        {
            "displayName":"ExperienceAvaya SA-9000000008",
            "accountId":"OUDRAM"
        },
        {
            "displayName":"CCaaS ALPHA - 2",
            "accountId":"ATZPEZ"
        },
        {
            "displayName":"3PartyTest",
            "accountId":"LIQZCR"
        }
        // {
        //     "displayName":"ExperienceAvaya UK-LAB",
        //     "accountId":"NLYYBX"
           
        // }

    ],
    "lang":[
        "Chinese",
        "English",
        "French",
        "German",
        "Hindi",
        "Japanese",
        "Russian",
        "Spanish"
    ]
  
}
