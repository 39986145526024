import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { KeycloakConfig } from '../../environments/environment';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material';
import { DialogErrorComponent } from '../dialog-error/dialog-error.component'
@Injectable({
  providedIn: 'root'
})
export class TokenService {

  refresh = null;
  constructor(private http: HttpClient,public dialog: MatDialog) { }

  subject = new Subject();

  generateToken(KeycloakServerUrl: string,accountId: string): void {
    
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };

   // debugger;

    this.http.post<any>('https://ccaas-cc-chat.experience.avaya.com/getToken',{subject: localStorage.getItem('subject'),base_url:KeycloakServerUrl,accountId:accountId})
      .subscribe(data => {
        const token = data.token;
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('token', JSON.parse(JSON.stringify(token)));
        if (token.startsWith("jwt.")) {
          if (this.refresh != null) {
            clearTimeout(this.refresh)
          }
          const newLocal = atob(token.split('.')[2]);
          const jwt = JSON.parse(newLocal)
          const exp = jwt['exp']
          var diff = (exp * 1000) - new Date().getTime()
          diff = Math.floor(0.9 * diff)
          if (diff > 0) {
            this.refresh = setTimeout(() => {
              this.generateToken(KeycloakServerUrl, accountId);
            }, diff);
            console.log("refresh scheduled after " + diff + "ms")
          }
        }
        this.subject.next({success:true})
      },
      error=>{
        this.dialog.open(DialogErrorComponent, {
          data: {
            title: 'error',
            text: error.error.error ||'Error While Generating Token'
          },
          height: 'fit-content',
          width: '350px',
        });
      });
  }
}
