import {Component, Inject} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
    title:string
    text:string 
  }

@Component({
  selector: 'dialog-error',
  styleUrls:['dialog-error.component.css'],
  templateUrl: 'dialog-error.component.html',
})
export class DialogErrorComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
  }