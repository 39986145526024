import { Injectable } from '@angular/core';
import { Observable, of, throwError, EMPTY } from 'rxjs';
import { SessionCreateData } from './SessionCreateData';
import { SessionResponse } from './SessionResponse';

import { HttpClient } from '@angular/common/http';
import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, retry, retryWhen, delay, mergeMap, shareReplay } from 'rxjs/operators';
import { EngagementResponse } from './EngagementResponse';
import { EngagementNotification } from './EngagementNotification';
import { Message } from './Message';
import { MessageResponse } from './MessageResponse';
import { JoinEngagementRequest } from './JoinEngagementRequest';
import { JoinEngagementResponse } from './JoinEngagementResponse';
import { TokenService } from './services/token.service';
import MessagesPage from './types/Message';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};
const getErrorMessage = (maxRetry: number) =>
  'Tried to load Resource over XHR for ${maxRetry} times without success. Giving up :/';
const DEFAULT_MAX_RETRIES = 12;

export function delayedRetry(delayMs: number, maxRetry = DEFAULT_MAX_RETRIES) {
  let retries = maxRetry;
  return (src: Observable<any>) =>
    src.pipe(
      retryWhen((errors: Observable<any>) => errors.pipe(
        delay(delayMs),
        mergeMap(error => retries-- > 0 ? of(error) : throwError(getErrorMessage(maxRetry))
        ))
      )
    );
}

@Injectable({
  providedIn: 'root'
})
export class DigitalService {

  constructor(private http: HttpClient, private tokenService: TokenService) { }
  //serviceUrl = ''
  // allowCrossOriginUrl = "https://thingproxy.freeboard.io/fetch/";
  allowCrossOriginUrl = "";


  server = '';
  port = '';
  protocol = '';
  proxy = false;
  createdDate: Date = new Date();
  messageurl = 'media flowing inside';
  //serviceUrl2='';
  chatStatus = false;

  errorMessage = '';
  payload = 'av-state-connecting';
  status = '';
  engagementURL = '';
  sessionURL = '';
  notificationURL = '';
  serviceUrl = '';
  providerId = '';
  accountId = '';
  basePath = window.location.pathname;
  prefix = '';
  private sessionCreateData: SessionCreateData;
  private session: SessionResponse;
  private engagement: EngagementResponse;
  public dialogId: string;
  private customerIdentifier: string;
  private message: Message;

  public keycloakServerUrl: string;

  public saveSettings(server, port, protocol, messageurl, providerId, accountId): void {
    this.basePath = window.location.pathname;
    this.server = server;
    this.port = port;
    this.protocol = protocol;
    this.providerId = providerId;
    this.accountId = accountId;
    if (this.basePath === 'undefined' || this.basePath === '' || this.basePath === '/') {
      this.basePath = '/v1beta/';
    }


    if (this.basePath === '/v1/') {
      this.basePath = '/v1/';
      this.engagementURL = `${this.basePath}engagements`;
      this.sessionURL = `${this.basePath}sessions`;
      this.prefix = '/api/digital';
    } else if (this.basePath === '/v1beta/') {
      this.sessionURL = `${this.basePath}accounts/${accountId}/sessions`;
      this.engagementURL = `${this.basePath}accounts/${accountId}/engagements`;
      this.prefix = '/api/digital/channel';
    }
    this.notificationURL = '/v1/engagements';
    // console.log(providerId);
    //this.serviceUrl = this.protocol + '://' + this.server + ':' + this.port+'/msg-web-gateway';
    // this.serviceUrl2 = this.protocol + '://' + this.server + ':' + this.port+'/msg-mock-provider-connector';
    this.messageurl = messageurl;
    if (this.messageurl === "media flowing outside") {

      
      // this.serviceUrl=this.protocol + '://' + this.server + ':' + this.port + '/msg-mock-provider-connector';
      this.serviceUrl = this.protocol + '://' + this.server + '/msg-mock-provider-connector';

      // console.log(this.engagementURL);
      // console.log(this.sessionURL);
      // console.log(this.serviceUrl);
      // console.log(this.messageurl);
    } else {

      this.engagementURL = `${this.prefix}${this.engagementURL}`;
      this.sessionURL = `${this.prefix}${this.sessionURL}`;
      this.notificationURL = '/api/digital/v1/engagements';
      //this.serviceUrl=this.protocol + '://' + this.server + ':' + this.port + '/msg-web-gateway';
      this.serviceUrl = this.protocol + '://' + this.server;
      // this.serviceUrl=this.protocol + '://' + this.server + ':' + this.port;
      // console.log(this.engagementURL);
      // console.log(this.sessionURL);
      // console.log(this.serviceUrl);
      // console.log(this.messageurl);
    }
    // console.log("Configuration saved");
    this.keycloakServerUrl = this.allowCrossOriginUrl + this.protocol + '://' + this.server ;
    this.tokenService.generateToken(this.keycloakServerUrl, accountId);
    // console.log('here----- after generating token',this.tokenService.subject)
  }

  public getkeycloakServerUrl(): string {
    return this.keycloakServerUrl;
  }

  public getUrl(): String {
    //var url = this.protocol + '://' + this.server + ':' + this.port+'/msg-web-gateway';
    return this.serviceUrl;

  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }

  };

  public setPayload(state: string): void {
    this.payload = state;
  }
  public useProxy(messageurl) {
    this.proxy = true;
    this.messageurl = messageurl;
  }
  public createSession(sessionCreateData: SessionCreateData): Observable<SessionResponse> {

    var baseURL = '';
    if (this.proxy)
      baseURL = '/proxy';
    else
      baseURL = this.allowCrossOriginUrl + this.serviceUrl;
    this.sessionCreateData = sessionCreateData;
    // console.log("messageURL - " + this.messageurl);
    // if(this.messageurl === "media flowing inside"){
    //   httpOptions.headers = httpOptions.headers.set('x-user', this.sessionCreateData.customerIdentifier);
    //   return this.http.post<SessionResponse>(createSessionUrl + '/v1/digital/sessions', sessionCreateData, httpOptions); 
    // }
    // createSessionUrl = this.serviceUrl2;
   

    var createSessionModel = {
      "accountId": this.sessionCreateData.accountId, //
      "contactNumber":this.sessionCreateData.contactNumber, //
      "customerIdentifier":this.sessionCreateData.customerIdentifier, //
      "displayName":this.sessionCreateData.displayName, //
      "emailAddress":this.sessionCreateData.emailAddress, //
      "firstName":this.sessionCreateData.firstName, //
      "lastName":this.sessionCreateData.lastName, //
      "providerId":this.sessionCreateData.providerId, //
      "channelProviderId": 'Experience',
      "customerIdentifiers": {
    		"emailAddresses": [
    			this.sessionCreateData.emailAddress
    			],
    		"phoneNumbers": [
    			this.sessionCreateData.contactNumber
    			]
    	}
    }
    return this.http.post<SessionResponse>(baseURL + this.sessionURL, createSessionModel, httpOptions);
  }
  public setSessionResponse(sessionResponse: SessionResponse) {
    this.session = sessionResponse;
  }
  public getSessionId(): string {
    if (this.session) {
      return this.session.sessionId;
    }
    return null;
  }
  public getConfig(): boolean {
    if (this.proxy || this.serviceUrl.length > 0)
      return true
    else
      return false;
  }

  //Modification Needed
  public terminateSession() {
    var baseURL = '';
    if (this.proxy)
      baseURL = '/proxy';
    else
      baseURL = this.allowCrossOriginUrl + this.serviceUrl;

    if (this.basePath === '/v1beta/') {
      return this.http.delete(baseURL + this.sessionURL + '/' + this.getSessionId() + '?reason=USER_CLOSED', httpOptions);
    } else {
    return this.http.post(baseURL + this.sessionURL + '/' + this.getSessionId() + ':terminate', { "reason": "USER_DISCONNECTED" }, httpOptions);
    }
  }
  public setSessionId(sessionId: string) {
    if (sessionId)
      this.session = null;
  }
  public createEngagement() {
    var sourceLanguage = localStorage.getItem('savedLanguage') ? localStorage.getItem('savedLanguage') : 'English';
    var createEngagementModel;
    if (this.basePath === '/v1beta/') {
      createEngagementModel = {
        "sessionId": this.getSessionId(),
        "channelId": "CHAT",
        "conversation": "New Car inquiry",
        "providerDialogId": this.getSessionId(),
        "correlationId": this.getDialogId(),
        "engagementParameters": {
          "language": sourceLanguage,
          "availableBalance":this.sessionCreateData.sessionParameters.available_balance,
          "customerType":this.sessionCreateData.sessionParameters.customer_type,
          "sourcePage":this.sessionCreateData.sessionParameters.source_page,
        }
      }
    } else {
      createEngagementModel = {
      "sessionId": this.getSessionId(),
      "accountId": this.sessionCreateData.accountId,
      "mediaType": "CHAT",
      "conversation": "New Car inquiry",
      "customerIdentifier": this.sessionCreateData.customerIdentifier,
      "channelProviderId": 'Experience',
      "correlationId": "request1",
      "contextParameters": {
        "language": sourceLanguage,
        "availableBalance":this.sessionCreateData.sessionParameters.availableBalance,
        "customerType":this.sessionCreateData.sessionParameters.customerType,
        "sourcePage":this.sessionCreateData.sessionParameters.sourcePage,
	    },
	    "channelId": "CHAT",
          "engagementParameters": this.getDialogId()
      }

      
    }

    // console.log(createEngagementModel , 'createEngagementModel')

    var baseURL = '';
    if (this.proxy)
      baseURL = '/proxy';
    else
      baseURL = this.allowCrossOriginUrl + this.serviceUrl;
    // if(this.messageurl === "media flowing inside"){
    //   httpOptions.headers = httpOptions.headers.set('x-user', this.sessionCreateData.customerIdentifier);
    // return this.http.post<EngagementCreatedResponse>(createSessionUrl + '/v1/digital/engagements',createEngagementModel , httpOptions);
    // }
    // createSessionUrl = this.serviceUrl2;
    //return this.http.post<EngagementResponse>(createSessionUrl + '/v1/engagements',createEngagementModel , httpOptions);
    return this.http.post<EngagementResponse>(baseURL + this.engagementURL, createEngagementModel, httpOptions);
  }
  public setEngagement(enagement: EngagementResponse) {
    this.engagement = enagement;
  }
  
  
  //This is actually disconnect engagement API
  public terminateEngagement(id = null , dialogId=null) {

    if(id == null){
      id=this.getEngagementId();
    }

    if(dialogId == null){
      dialogId=this.getDialogId();
    }

    var model = {
      "sessionId": this.getSessionId(),
      "dialogId": dialogId,
      "reason": "UNKNOWN"
    }

    
    var baseURL = '';
    if (this.proxy)
      baseURL = '/proxy';
    else
      baseURL = this.allowCrossOriginUrl + this.serviceUrl;
    //return this.http.post(createSessionUrl + '/v1/digital/engagements/'+this.enagement.engagementId+ ':disconnect',model , httpOptions);
    return this.http.post(baseURL + this.engagementURL + '/' + id + ':disconnect', model, httpOptions);
  }

  //Need to check
  public updateSession(param: string, val: string) {
    var baseURL = '';
    if (this.proxy)
      baseURL = '/proxy';
    else
      baseURL = this.allowCrossOriginUrl + this.serviceUrl;
    let data = '{"sessionParameters": {"' + param + '":"' + val + '"}}';

    if (this.basePath === '/v1beta/') {
      return this.http.post(baseURL + this.sessionURL + this.getSessionId() + ':updateSessionParameters', JSON.parse(data), httpOptions);
    } else {
    return this.http.post(baseURL + this.sessionURL + this.getSessionId() + ':update', JSON.parse(data), httpOptions);
    }
  }
  public getEngagementId(): string {
    if (this.engagement) {
      return this.engagement.engagementId;
      // console.log('returning dialogId as : ' + this.engagement.engagementId);
    }
    return null;
  }
  public getDialogId(): string {
    if (this.engagement) {
      if (this.engagement.dialogId != null) {
        this.dialogId = this.engagement.dialogId;
        // console.log('returning dialogId as : ' + this.dialogId);
        return this.dialogId;
      }
      else if (this.engagement.dialogs[0].dialogId != null) {
        this.dialogId = this.engagement.dialogs[0].dialogId;
        // console.log('returning dialogId as : ' + this.dialogId);
        return this.dialogId;
      }
    }
    return null;
  }
  public getNotifications():Promise<EngagementNotification[]> {

    var baseURL = '';
    if (this.proxy)
      baseURL = '/proxy';
    else
      baseURL = this.allowCrossOriginUrl + this.serviceUrl;
    // console.log("URL for notification-" + baseURL + this.engagementURL + '/' + this.engagement.engagementId + '/notifications?dialogId=' + this.getDialogId() + '&sessionId=' + this.session.sessionId);
    return this.http.get<EngagementNotification[]>(baseURL + this.notificationURL + '/' + this.engagement.engagementId + '/notifications?dialogId=' + this.getDialogId() + '&sessionId=' + this.session.sessionId, httpOptions).toPromise();
  }
  public updatErrorMessage(errorMessage: string, created: any): void {

    this.errorMessage = errorMessage;
    this.createdDate = created;
  }
  public updateNofitifactionDate(created: Date): void {
    if (this.createdDate) {
      if (created > this.createdDate) {
        this.createdDate = created;
      }
    }
  }
  public getCreated(): Date {
    return this.createdDate;
  }
  public getStatus(): string {
    return this.status;
  }
  public setStatus(status: string): void {
    this.status = status;
  }

  //Message model needs to change
  public sendMessage(message: Message): Observable<MessageResponse> {
    // console.log("Entered sendMessage in digital service.");
    var baseURL = '';
    if (this.proxy)
      baseURL = '/proxy';
    else
      baseURL = this.allowCrossOriginUrl + this.serviceUrl;
    this.message = message;
    // console.log("About to make REST call with message: " + message.body);
    // if(this.messageurl === "media flowing inside"){
    //   httpOptions.headers = httpOptions.headers.set('x-user', this.sessionCreateData.customerIdentifier);
    // return this.http.post<MessageResponse>(createSessionUrl + '/v1/digital/engagements/'+this.enagement.engagementId+'/messages',message,httpOptions);
    // }
    // createSessionUrl = this.serviceUrl2;
    return this.http.post<MessageResponse>(baseURL + this.engagementURL + "/" + this.engagement.engagementId + '/messages', message, httpOptions);
  }
  public getMessage(): Promise<MessageResponse[]> {

    var baseURL = '';
    if (this.proxy)
      baseURL = '/proxy';
    else
      baseURL = this.allowCrossOriginUrl + this.serviceUrl;

    return this.http.get<MessageResponse[]>(baseURL + this.engagementURL + "/" + this.engagement.engagementId + '/messages?sessionId=' + this.session.sessionId + '&dialogId=' + this.getDialogId(), httpOptions).toPromise()
  }

  public getMessageV1Beta(pageNumber): Promise<MessagesPage> {

    var baseURL = '';
    if (this.proxy)
      baseURL = '/proxy';
    else
      baseURL = this.allowCrossOriginUrl + this.serviceUrl;

    return this.http.get<MessagesPage>(baseURL + this.engagementURL + "/" + this.engagement.engagementId + '/messages?sessionId=' + this.session.sessionId + '&dialogId=' + this.getDialogId() +'&pageNumber='+pageNumber, httpOptions).toPromise()
     
  }

  public getCustomerIdentifier(): string {
    if (this.session) {
      // console.log('customerIdentifier = ' + this.session.customerIdentifier);
      return this.session.customerIdentifier;
    }
    return null;
  }

  public getCustomerSenderName(): string {
    if (this.session) {
      // console.log('customerSenderName = ' + this.session.displayName);
      return this.session.displayName;
    }
    return null;
  }


  public getEngagements(): Observable<EngagementResponse[]> {
    var baseURL = '';
    if (this.proxy)
      baseURL = '/proxy';
    else
      baseURL = this.allowCrossOriginUrl + this.serviceUrl;
    return this.http.get<EngagementResponse[]>(baseURL + this.engagementURL + '?sessionId=' + this.getSessionId(), httpOptions);
  }

  // This one is called after the dialog showing all existing engagements for a session opens and we click on one of them
  public getEngagement(engagementId: String): Observable<EngagementResponse> {
    var baseURL = '';
    if (this.proxy)
      baseURL = '/proxy';
    else
      baseURL = this.allowCrossOriginUrl + this.serviceUrl;
    return this.http.get<EngagementResponse>(baseURL + this.engagementURL + "/" + engagementId, httpOptions);
  }
  // This one is called from inside the function where the above method is called, after getting the engagementId
  public joinEngagement(joinEngagementRequest: JoinEngagementRequest, engagementId: String): Observable<JoinEngagementResponse> {
    var baseURL = '';
    if (this.proxy)
      baseURL = '/proxy';
    else
      baseURL = this.allowCrossOriginUrl + this.serviceUrl;
    return this.http.post<JoinEngagementResponse>(baseURL + this.engagementURL + "/" + engagementId + ":join", joinEngagementRequest, httpOptions);
  }

  public getSessionResponse(): SessionResponse {
    return this.session;
  }

  public setChatStatus(status: boolean) {
    this.chatStatus = status;
  }

  public getChatStatus(): boolean {
    return this.chatStatus;
  }



}
